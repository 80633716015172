.search-filter {
	&__wrapper {
		padding: 2rem;
	}
	&__search {
		width: 150px !important;
	}
	&__filter {
		width: 150px !important;
	}
}

.pay-period-filter {
	width: 200px !important;
}

.button-container {
	display: flex;
}

.custom-row {
	display: flex; /* Ensure flexbox layout */
}

.custom-col {
	margin-top: 16px; /* Adjust spacing between columns */
}

.custom-col:last-child {
	margin-right: 0; /* Remove margin from the last column */
}

.batch-edit {
	width: 16rem;
	border-radius: 100rem;
	background-color: #344735;
	color: white;
	min-height: 4.5rem;
	font-size: 1.6rem;
	font-weight: 600;
	margin-left: 2rem;
	margin-right: 2rem;
	border: none;
}

.batch-edit:hover {
	text-decoration: none;
	border: none;
	color: white !important;
	background-color: #344735 !important;
}

.time-sheet-modal {
	&__header {
		padding: 1rem 3rem !important;
		display: flex !important;
		flex-direction: row !important;
		gap: 1.1rem;
		// align-items: center;
		width: 100%;
		min-height: 9.6rem !important;
		justify-content: space-between;

		&--left {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2.7rem;
			width: 90%;
		}

		// &--avatar {
		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// 	width: 7rem;
		// 	height: 7rem;

		// 	img {
		// 		width: 7rem;
		// 		height: 7rem;
		// 		border-radius: 3.4rem;
		// 		object-fit: cover !important;
		// 		object-position: top !important;
		// 	}
		// }

		// &--upload {
		// 	button {
		// 		display: flex;
		// 		justify-content: center;
		// 		align-items: center;
		// 	}

		// 	&--text {
		// 		color: #999999;
		// 	}
		// }

		&-delete {
			border: none !important;

			&:hover {
				cursor: pointer;
			}
		}
	}

	&__body {
		&__buttons {
			gap: 4rem !important;
		}

		&__button {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			height: 3.9rem !important;
			width: 13rem !important;
			font-size: 1.7rem;
			border: 1px solid black;
			border-radius: 2.1rem;

			&-inside {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
			}
		}

		&__send-email {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 4.2rem !important;
			width: 17.4rem !important;
			font-size: 1.7rem;
			color: white;
			border: 1px solid black;
			background-color: black;
			border-radius: 2.1rem;

			&:hover {
				color: white;
				border: 1px solid black;
				background-color: black !important;
				border-radius: 2.1rem;
			}

			&-inside {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
			}
		}

		&__export {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			height: 4.2rem !important;
			width: 20rem !important;
			font-size: 1.7rem;
			color: white;
			border: 1px solid black;
			background-color: black;
			border-radius: 2.1rem;
		}
	}
}

.search-filter__batch {
	padding-left: 3rem;
	padding-right: 3rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
