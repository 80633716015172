.confirm-delete-model__button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-top: 3.2rem;
	&--btn {
		border: none;
		font-size: 1.8rem;
		padding: 1.3rem 3rem;
		border-radius: 500px;
		cursor: pointer;
		font-weight: 600;
	}
	&--save {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #000;
		font-size: 1.6rem;
		color: #fff;
		font-weight: 600;
		border-radius: 1000px !important;
		gap: 8px;
		padding: 1.3rem 2.8rem;
		border: none;
		cursor: pointer;
	}
	&--cancel {
		background: #fff;
		color: #000;
		border: 1px solid #000;
	}
}
.confirm-delete-model__button--btn:disabled,
.confirm-delete-model__button--cancel:disabled {
	cursor: not-allowed !important;
	opacity: 0.6; /* Optional: Makes the disabled state visually distinct */
}

.customCell {
	display: flex !important;
	justify-content: center !important;
}

.tab-content {
	max-height: 40vh; /* 80% of the viewport height */
	overflow-y: auto; /* Enable vertical scrolling within the content */
	padding: 10px; /* Optional: Add some padding inside each TabPane */
	box-sizing: border-box; /* Ensure padding is included in height calculation */
}

.delete-text {
	color: #666;
	font-size: 8px !important;
	font-weight: 400;
}

.table-container {
	height: 80%; /* Adjust percentage as needed */
	overflow: auto; /* Ensures the table scrolls */
}

.dynamic-table {
	overflow: auto;
	background-color: #fff !important;
	border-radius: 10px !important;
	// border-bottom-left-radius: 10px !important;
	// border-bottom-right-radius: 10px !important;

	&__pagination {
		justify-content: center !important;
		.ant-pagination-item {
			border-radius: 50%;
		}
		.ant-pagination-item-active {
			border-color: #000;
			background-color: #000;
			&:hover {
				border: none !important;
				border-width: 0px !important;
			}
			a {
				color: #fff !important;
			}
		}
	}
	.table-edit-icon,
	.table-delete-icon {
		cursor: pointer;
	}
	&__filter-sync {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&--sync {
			gap: 1rem;
			padding-right: 2rem;
			display: flex;
			align-items: center;
		}
	}
	.pipe {
		width: 1px;
		height: 32.308px;
		background: #bfbfbf;
	}
	.hrs-wrapper {
		display: flex;
		align-items: center;
		gap: 4px;
	}
	.split-text {
		color: #666;
		font-size: 10px;
		font-weight: 400;
	}
	.delete-text {
		color: #666;
		font-size: 10px;
		font-weight: 400;
	}
	.action-svg {
		flex-direction: column;
	}
	.time-picker:not(.ant-picker-status-error) {
		// border: 1px solid #d4d7dc !important;
		// box-shadow: none !important;
	}

	.table-select-dropdown {
		width: 70% !important;
	}
}

.split-table-button__wrapper {
	display: flex;
	align-items: center;
	gap: 15px;
	.split-table-button {
		font-size: 14px;
		font-weight: 500;
		padding: 1rem 2.7rem;
		border: none;
		border-radius: 222px;
		cursor: pointer;
	}
	.split-table-button-save {
		color: #fff;
		background: #000;
		border: 2px solid #000;
	}
	.split-table-button-cancel {
		color: #a2a7ad;
		background: #e3e4e7;
		border: 2px solid #e3e4e7;
	}
}

.split-table {
	// background: red !important;
	.ant-table-footer {
		border-top: 1px solid #d4d7dc;
	}
	margin-right: 50px;
}

.border-error {
	&:hover {
		border-color: #ff4d4f !important;
	}
	&:focus {
		border-color: #ff4d4f !important;
	}
	&:active {
		border-color: #ff4d4f !important;
	}
	border-color: #ff4d4f !important;
	.ant-select-selector,
	.ant-input {
		&:hover {
			border-color: #ff4d4f !important;
		}
		&:focus {
			border-color: #ff4d4f !important;
		}
		&:active {
			border-color: #ff4d4f !important;
		}
		border-color: #ff4d4f !important;
	}
}

.border-normal {
	border-color: #d2d4d8 !important;
}

/* .employee-over-hours {
	color: red !important;
}

.employee-normal-hours {
	color: green !important;
} */

.container {
	margin: 25px 10px 10px 0px;
	display: flex;
	justify-content: flex-end; /* Aligns items to the end */
	width: 100%;
	gap: 16px; /* Optional: adds space between items */
}

.name-container {
	width: 150px; /* Set a fixed width */
	overflow: hidden; /* Hide anything that overflows */
	white-space: nowrap; /* Prevent the text from wrapping to the next line */
	text-overflow: ellipsis; /* Add an ellipsis (...) if the text is truncated */
	position: relative;
}

.name {
	overflow: hidden; /* Hide anything that overflows */
	white-space: nowrap; /* Prevent the text from wrapping to the next line */
	text-overflow: ellipsis; /* Add an ellipsis (...) if the text is truncated */
	display: block;
	transition: white-space 0.3s ease; /* Smooth transition when the hover effect is triggered */
}

.negativeValue {
	:global {
		.ant-input-number-input {
			color: red !important;
		}
	}
}
