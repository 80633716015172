.preview-container__main {
	.ant-checkbox-inner {
		height: 25px !important;
		width: 25px !important;
	}

	.ant-radio-wrapper {
		display: flex !important;
		align-items: center !important;
	}

	:where(.css-dev-only-do-not-override-1m62vyb).ant-checkbox-checked
		.ant-checkbox-inner {
		background-color: green !important;
		border-color: green !important;
	}

	:where(.css-dev-only-do-not-override-1m62vyb).ant-checkbox-checked
		.ant-checkbox-inner:after {
		width: 8px !important;
		height: 15px !important;
	}
}

.preview-container__main-input-error {
	.ant-select-selector {
		border-color: red !important;

		&:hover {
			border-color: red !important;
		}

		&:focus {
			border-color: red !important;
		}

		&:active {
			border-color: red !important;
		}
	}
}
.ant-modal-wrap {
	z-index: 9999 !important;
}
