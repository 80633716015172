.dynamic-table {
	overflow: auto;
	background-color: #fff !important;
	border-radius: 10px;

	&__pagination {
		justify-content: center !important;
		.ant-pagination-item {
			border-radius: 50%;
		}
		.ant-pagination-item-active {
			border-color: #000;
			background-color: #000;
			&:hover {
				border: none !important;
				border-width: 0px !important;
			}
			a {
				color: #fff !important;
			}
		}
	}
	.table-edit-icon,
	.table-delete-icon {
		cursor: pointer;
	}
}

.sync-date-picker {
	&:where(.css-dev-only-do-not-override-1m62vyb).ant-picker
		.ant-picker-input
		> input:hover {
		border-color: red !important;
	}
}
