.payroll-summary-report {
	.journal-total-row td {
		border-top: 2px solid #666666 !important;
		border-bottom: 2px solid #666666 !important;
		border-right: none !important;
		background-color: #f7f7f7 !important;
		font-weight: 600;
	}

	.journal-total-row {
		position: sticky;
		bottom: 0;
		left: 0;
		z-index: 999;
	}
}