.time-activity__log-ant-sheet {
	background-color: white !important;
}

.time-logs-tab-export {
	position: absolute;
	top: -5.7rem;
	right: 10px;
	display: flex;
	gap: 3rem !important;
}

.dynamic-table {
	&__filter-sync {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&--sync {
			gap: 1rem;
			padding-right: 2rem;
			display: flex;
			align-items: center;
		}
	}
}

.pipe {
	width: 1px;
	height: 32.308px;
	background: #bfbfbf;
}

.log-sheet {
	&__wrapper {
		height: 76px;
		background-color: #fff;
		padding: 2.6rem 3rem 10px 2rem;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		border-bottom: 1px solid #d4d7dc;
	}
	&__left {
		display: flex;
		gap: 28px;
		&--btn-time-log {
			cursor: pointer;
			border: none;
			background: none;
			color: #344735;
			font-size: 1.8rem;
			font-weight: 500;
			position: relative;
		}
	}
	&__right {
		display: flex;
		align-items: center;
		gap: 2rem;
		&--hours-over {
			display: flex;
			align-items: center;
			gap: 9px;
			& > label {
				color: #27272e;
				font-size: 16px;
			}
		}
		&--add-time-log {
		}
	}
}

.time_logs_after {
	&::after {
		content: '';
		position: absolute;
		height: 5px;
		color: #344735;
		background: #344735;
		left: 18px;
		bottom: 0;
		width: 81px;
	}
}

.time_sheet_after {
	&::after {
		content: '';
		position: absolute;
		height: 5px;
		color: #344735;
		background: #344735;
		left: 128px;
		bottom: 0;
		width: 95px;
	}
}

.preview-button {
	width: 20rem;
	height: 4.2rem;
	background-color: #000000;
	color: #ffffff;
	border: 2px solid #000000;
	border-radius: 5rem;
	font-size: 1.5rem;

	&:hover {
		cursor: pointer;
	}
}

.time-activity__action-header-buttons {
	display: flex;
	gap: 2rem;
}

.time-sheet-modal__header {
	padding: 1.1rem 1rem !important;
	display: flex;
	gap: 1.1rem;
	align-items: center;
	width: 100%;
	min-height: 5.6rem !important;
	justify-content: space-between;

	&--left {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 2.7rem;
	}

	&--avatar {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 7rem;
		height: 7rem;

		img {
			width: 7rem;
			height: 7rem;
			border-radius: 3.4rem;
			object-fit: cover !important;
			object-position: top !important;
		}
	}

	&--upload {
		button {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&--text {
			color: #ffffff;
		}
	}

	&-delete {
		border: none !important;

		&:hover {
			cursor: pointer;
		}
	}
}

.CustomMultipleFormModal {
	width: 70% !important;
}
