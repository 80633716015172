#notfound {
	position: relative;
	height: 100vh;
	background: rgb(248, 241, 233);
}

#notfound .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound {
	max-width: 767px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
}

.notfound .notfound-error {
	position: relative;
	height: 180px;
	margin-bottom: 20px;
	z-index: -1;
}

.notfound .notfound-error h1 {
	font-family: montserrat, sans-serif;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 224px;
	font-weight: 900;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: -12px;
	color: #42546573;
	text-transform: uppercase;
	letter-spacing: -20px;
}

.notfound .notfound-error h2 {
	font-family: montserrat, sans-serif;
	position: absolute;
	left: 0;
	right: 0;
	top: 110px;
	font-size: 42px;
	font-weight: 700;
	color: #344735;
	text-transform: uppercase;
	text-shadow: 0 2px 0 #bfbac4;
	margin: 0;
}

.notfound .notfound-error h3 {
	font-family: montserrat, sans-serif;
	position: absolute;
	left: 0;
	right: 0;
	top: 185px;
	font-size: 20px;
	font-weight: 700;
	color: #344735;
	margin: 0;
}

.notfound p {
	font-family: montserrat, sans-serif;
	position: relative;
	left: 0;
	right: 0;
	top: 15px;
	font-size: 15px !important;
	font-weight: 500;
	color: black;
	margin: 0;
}

.notfound .returnLogin {
	margin-top: 30px;
	background-color: #344735;
}

.notfound .returnLogin:hover {
	margin-top: 30px;
	background-color: #344735 !important;
}

.goback-text {
	color: #344735 !important;
}

@media only screen and (max-width: 767px) {
	.notfound .notfound-error h2 {
		font-size: 24px;
	}
}

@media only screen and (max-width: 480px) {
	.notfound .notfound-error h1 {
		font-size: 182px;
	}
}

@media only screen and (max-width: 250px) {
	.notfound .notfound-error h3 {
		font-size: 340px;
	}
}

/* @media only screen and (max-width:300px) {
    .notfound .notfound-error a {
        font-size: 200px
    }
} */
