.preview-container {
	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-title {
			font-size: 2.5rem;
			font-weight: 400;
		}

		margin-bottom: 2rem;

		&-close {
			:hover {
				cursor: pointer;
			}
		}
	}

	&__main {
		background-color: white;
		border-radius: 1rem;

		&-title {
			font-size: 2.4rem;
			font-weight: 600;
			margin-bottom: 1.5rem;
		}

		&-second-title {
			color: rgb(121, 121, 121);
			font-size: 1.6rem;
			margin-bottom: 1.5rem;
			margin-top: -1rem;
		}

		&-label {
			min-width: 20rem;
			margin-right: 2rem;
			font-weight: 600;
			font-size: 1.6rem;
		}

		&-second-label {
			min-width: 20rem;
			margin-right: 2rem;
			font-weight: 600;
			font-size: 1.6rem;
		}

		&-table {
			&-add {
				color: #153fcd;

				&:hover {
					cursor: pointer;
				}
			}

			&-delete {
				color: #e74032;

				&:hover {
					cursor: pointer;
				}
			}

			&-end {
				td {
					background-color: #f8f8f8;
					border-top: 2px solid #666666 !important;
					border-bottom: 2px solid #666666 !important;
					border-left: none !important;
					border-right: none !important;
				}
			}
		}

		&-operator {
			display: flex;
			gap: 2rem;
			justify-content: center;

			&-button {
				padding: 0.3rem 0.8rem;
				border: 1px solid rgb(192, 192, 192);
				outline: none;
				border-radius: 0.7rem;
				background-color: white;

				&-active {
					background-color: #555 !important;
					color: white;
				}

				&:hover {
					cursor: pointer;
				}
			}
		}

		&-checkbox {
			gap: 1rem;
		}

		&-radio {
			gap: 2rem;
			display: flex;
		}

		&-input {
			width: 100% !important;
			font-size: 1.6rem;
			// font-weight: 500;

			&-container {
				width: 70rem !important;
				display: flex;
				margin-bottom: 3rem;
				align-items: center;
			}

			&-multiple {
				display: flex;
				gap: 2rem;
			}

			&-error {
				border-color: red !important;

				&:hover {
					border-color: red !important;
				}

				&:focus {
					border-color: red !important;
				}

				&:active {
					border-color: red !important;
				}
			}
		}

		&-add-rule {
			padding: 3rem;
			border-bottom: 1px solid rgb(193, 193, 193);

			&__content {
				table {
					width: 100%;
					border: 1px solid rgb(168, 168, 168);
					border-collapse: collapse;
				}

				th {
					padding: 2rem;
				}

				td {
					border: 1px solid rgb(168, 168, 168);
					border-collapse: collapse;
					padding: 2rem;
				}
			}
		}

		&-trigger-process {
			padding: 3rem;
			border-bottom: 1px solid rgb(193, 193, 193);
		}

		&-criteria {
			padding: 3rem;
			border-bottom: 1px solid rgb(193, 193, 193);
		}

		&-actions {
			padding: 3rem;
			border-bottom: 1px solid rgb(193, 193, 193);
		}

		&-buttons {
			padding: 3rem;
			display: flex;
			gap: 2rem;

			&-cancel {
				border: 1px solid black;
				color: black;
				background-color: white;
				border-radius: 4rem;
				width: 10rem;
				height: 4rem;

				&:hover {
					cursor: pointer;
				}
			}

			&-save {
				border: none;
				color: white;
				background-color: #344735;
				border-radius: 4rem;
				width: 10rem;
				height: 4rem;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

.input-field {
	font-size: 1.6rem !important;
	padding: 0.6rem 1rem !important;
}