.confirm-delete-model__button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-top: 3.2rem;
	&--btn {
		border: none;
		font-size: 1.8rem;
		padding: 1.3rem 3rem;
		border-radius: 500px;
		cursor: pointer;
		font-weight: 600;
	}
	&--save {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #000;
		font-size: 1.6rem;
		color: #fff;
		font-weight: 600;
		border-radius: 1000px !important;
		gap: 8px;
		padding: 1.3rem 2.8rem;
		border: none;
		cursor: pointer;
	}
	&--cancel {
		background: #fff;
		color: #000;
		border: 1px solid #000;
	}
}

.customCell {
	display: flex !important;
	justify-content: center !important;
}
