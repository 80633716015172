.custom-rules-container {
	background-color: white;
}

.add-rule-button {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #344735;
	font-size: 1.6rem;
	color: #fff;
	font-weight: 600;
	border-radius: 1000px !important;
	gap: 8px;
	padding: 1.3rem 2.8rem;
	border: none;
	cursor: pointer;

	&:hover {
		cursor: pointer;
	}
}

.custom__rules {
	&-tabs {
		background-color: white;
	}
}
