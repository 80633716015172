.confirm-delete-model {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	padding-left: 34px;
	padding-right: 34px;

	&__title {
		font-size: 26px;
		letter-spacing: -0.13px;
		width: 231px;
		margin: 2.3rem auto 0 auto;
		text-align: center;

		&--delete {
			color: #e74032;
		}
	}
}
.confirm-delete-model__button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-top: 3.2rem;
	&--btn {
		border: none;
		font-size: 1.8rem;
		padding: 1.3rem 3rem;
		border-radius: 5000px;
		cursor: pointer;
		font-weight: 600;
	}
	&--save {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #e74032;
		font-size: 1.6rem;
		color: #fff;
		font-weight: 600;
		border-radius: 1000px !important;
		border: 1px solid #e74032;
		cursor: pointer;
		width: 13rem;
		height: 50px;
		&:hover {
			background: #e74032;
		}
	}
	&--cancel {
		background: #fff;
		color: #000;
		border: 1px solid #000;
		width: 142px;
	}
}
