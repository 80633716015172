.draggable_table {
	&__rule {
		color: #153fcd;
		display: flex;
		gap: 1rem;
		align-items: center;
		font-weight: 500;
		font-size: 1.6rem;
		&-employee {
			border-bottom: 1px solid #153fcd;
		}
		&:hover {
			cursor: pointer;
		}
	}

	&__status {
		font-weight: 400;
		font-size: 1.6rem;
		&-active {
			display: flex;
			align-items: center;
			gap: 1rem;
			color: #009049;
		}
		&-inactive {
			display: flex;
			align-items: center;
			gap: 1rem;
			color: #e74032;
		}
	}

	&__actions {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		&-copy {
			&:hover {
				cursor: pointer;
			}
		}

		&-edit {
			&:hover {
				cursor: pointer;
			}
		}

		&-delete {
			&:hover {
				cursor: pointer;
			}
		}
	}
}
