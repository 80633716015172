.employee-container {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2rem;

		&--title {
			font-weight: 700;
			font-size: 2.4rem;
		}

		&--actions {
			display: flex;

			&-preview {
				width: 23rem;
				height: 4.2rem;
				background-color: #000000;
				color: #ffffff;
				border: 2px solid #000000;
				border-radius: 5rem;
				font-size: 1.5rem;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

.search-filter {
	padding: 2rem !important;
}

.table-employee thead th {
	background: #485949 !important;
	color: #fff !important;
	border-radius: 0px !important;
	padding: 1.4rem !important;
}

.table-employee thead th:hover {
	background: #485949 !important;
	color: #fff !important;
	cursor: pointer;
}

.table-employee {
	background-color: #fff !important;
}

.clock {
	display: flex !important;
}

.dynamic-table {
	&__employee {
		color: #2c9f1c !important;
		// text-decoration: none !important;

		&-name {
			margin-right: 1rem !important;
		}
	}
}
