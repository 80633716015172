.global-layout {
	height: 100vh;

	&__body {
		display: flex;

		// &--body {
		// 	background: #f8f1e9;
		// 	padding: 2.1rem 3rem 3.6rem 3rem;
		// 	position: relative !important;
		// }

		&--body-widthFull {
			width: 100%;
			background: #f8f1e9;
			padding: 2.1rem 3rem 3.6rem 3rem;
			position: relative !important;
			height: calc(100vh - 70px);
			overflow-y: scroll;
		}

		&--body-widthFixed {
			width: calc(100vw - 200px) !important;
			background: #f8f1e9;
			padding: 2.1rem 3rem 3.6rem 3rem;
			position: relative !important;
			height: calc(100vh - 70px);
			overflow-y: auto;
		}
	}

	&__body--sidebar-show {
		display: block;
	}

	&__body--sidebar-hide {
		display: none;
	}
}