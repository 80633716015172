.employee-cost-import-modal {
    .ant-modal-footer {
        margin: 0px !important;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-label {
            font-size: 20px;
            font-weight: 600;
        }
    }

    &__container {
        padding-top: 20px;

        &--notes {
            padding-bottom: 20px;
        }

        &--buttons {
            margin-top: 20px;
            display: flex;
        }

        &--table {
            padding-top: 20px;
        }
    }
}