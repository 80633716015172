.ant-table-header {
	border-radius: 0px !important;
}

.cost-allocation-row td {
	border-top: 1px solid #666666 !important;
	border-bottom: 1px solid #666666 !important;
	border-right: none !important;
}

.cost-allocation-row:last-child td {
	border-bottom: none !important;
}

.cost-allocation-row .total-row {
	background-color: #f7f7f7 !important;
}

// .ant-input-number {
// 	width: 8rem !important;
// }

.cost-allocation-column-title {
	display: flex;
	gap: 8px;

	svg {
		min-width: 16px;
		height: 16px;
	}

	p {
		&:first-child {
			line-height: 2.3 !important;
		}

		line-height: 22px;
	}
}

.width-130 {
	width: 130px !important;
}

.width-90 {
	width: 90px !important;
}

.last-column {
	border-right: 1px dashed #c2c2c2 !important;
}

.cost-allocation {
	td {
		border-bottom: 1px solid #d4d7dc;
		border-top: 1px solid #d4d7dc;
	}

	td.cell-fff {
		background-color: #fff !important;
	}

	td.cell-e7eff8 {
		background-color: #e7eff8 !important;
	}

	td.cell-e1f1eb {
		background-color: #e1f1eb !important;
	}

	td.cell-f3ede7 {
		background-color: #f3ede7 !important;
	}

	td.cell-FCF9E1 {
		background-color: #fcf9e1 !important;
	}

	td.cell-DFE9ED {
		background-color: #dfe9ed !important;
	}

	.ant-table-footer {
		background-color: #fff;
	}
}

.cost-allocation-footer {
	height: 30px;
}

.cost-allocation-total-row {
	position: sticky;
	bottom: 0;
	left: 0;
	z-index: 999;
	outline: auto;
}

.cost-allocation-table {
	.ant-table-footer {
		display: none;
	}
}
