.side-drawer-form {
	&__inputs {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		// column-gap: 3rem;
		// row-gap: 3rem;
		& > div {
			padding-left: 3.636rem;
			padding-right: 3.636rem;
		}
	}
	&__role {
		&--label {
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			gap: 0.8rem;

			font-size: 1.6rem;
			font-weight: 700;
			color: #27272e;
		}
		&--select {
			font-size: 1.6rem;
			font-weight: 700;
			color: #27272e;
			width: 100%;
		}
	}
	&__buttons {
		display: flex;
		align-items: center;
		gap: 2rem;
		padding-left: 3.336rem;
		padding-right: 3.336rem;
		&--btn {
			border: none;
			font-size: 1.8rem;
			padding: 1.8rem 4rem;
			border-radius: 5000px;
			cursor: pointer;
			font-weight: 600;
		}
		&--save {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #344735;
			font-size: 1.6rem;
			color: #fff;
			font-weight: 600;
			border-radius: 1000px !important;
			border: none;
			cursor: pointer;
			width: 13rem;
			min-height: 5.2rem;
			&:hover {
				background: #344735 !important;
			}
		}
		&--cancel {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			border: 2px solid #e3e4e7;
			box-shadow: none;
			font-size: 1.6rem;
			color: #a2a7ad;
			font-weight: 600;
			border-radius: 1000px !important;
			cursor: pointer;
			width: 13rem;
			min-height: 5.2rem;
			&:hover {
				background: #fff !important;
				color: #a2a7ad !important;
			}
		}
	}
}
