.dynamic-permission-table {
	padding-left: 3.6rem;
	padding-right: 3.6rem;
}
.side-drawer-form {
	padding: 0px 32px 32px 32px;
	&__buttons {
		display: flex;
		align-items: center;
		gap: 2rem;
		margin-top: 5rem;
		&--btn {
			border: none;
			font-size: 1.8rem;
			padding: 1.8rem 4rem;
			border-radius: 5000px;
			cursor: pointer;
			font-weight: 600;
		}
		&--save {
			background: #344735;
			color: #fff;
			border: 1px solid #344735;
			width: 127px;
		}
		&--cancel {
			background: #fff;
			color: #000;
			border: 1px solid #000;
			width: 127px;
		}
	}
}
