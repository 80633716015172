.dynamic-table {
	overflow: auto;
	background-color: #fff !important;
	border-radius: 10px !important;
	// border-bottom-left-radius: 10px !important;
	// border-bottom-right-radius: 10px !important;

	&__pagination {
		justify-content: center !important;
		.ant-pagination-item {
			border-radius: 50%;
		}
		.ant-pagination-item-active {
			border-color: #000;
			background-color: #000;
			&:hover {
				border: none !important;
				border-width: 0px !important;
			}
			a {
				color: #fff !important;
			}
		}
	}
	.table-edit-icon,
	.table-delete-icon {
		cursor: pointer;
	}
	&__filter-sync {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&--sync {
			gap: 1rem;
			padding-right: 2rem;
			display: flex;
			align-items: center;
		}
	}
	.pipe {
		width: 1px;
		height: 32.308px;
		background: #bfbfbf;
	}
	.hrs-wrapper {
		display: flex;
		align-items: center;
		gap: 4px;
	}
	.split-text {
		color: #666;
		font-size: 10px;
		font-weight: 400;
	}
	.delete-text {
		color: #666;
		font-size: 10px;
		font-weight: 400;
	}
	.action-svg {
		flex-direction: column;
	}
	.time-picker:not(.ant-picker-status-error) {
		// border: 1px solid #d4d7dc !important;
		// box-shadow: none !important;
	}

	.table-select-dropdown {
		width: 70% !important;
	}
}

.split-table-button__wrapper {
	display: flex;
	align-items: center;
	gap: 15px;
	.split-table-button {
		font-size: 14px;
		font-weight: 500;
		padding: 1rem 2.7rem;
		border: none;
		border-radius: 222px;
		cursor: pointer;
	}
	.split-table-button-save {
		color: #fff;
		background: #344735;
		border: 2px solid #344735;
	}
	.split-table-button-cancel {
		color: #a2a7ad;
		background: #e3e4e7;
		border: 2px solid #e3e4e7;
	}
}

.split-table {
	// background: red !important;
	.ant-table-footer {
		border-top: 1px solid #d4d7dc;
	}
	margin-right: 50px;
}

.border-error {
	&:hover {
		border-color: #ff4d4f !important;
	}
	&:focus {
		border-color: #ff4d4f !important;
	}
	&:active {
		border-color: #ff4d4f !important;
	}
	border-color: #ff4d4f !important;
	.ant-select-selector,
	.ant-input {
		&:hover {
			border-color: #ff4d4f !important;
		}
		&:focus {
			border-color: #ff4d4f !important;
		}
		&:active {
			border-color: #ff4d4f !important;
		}
		border-color: #ff4d4f !important;
	}
}

.border-normal {
	border-color: #d2d4d8 !important;
}

.employee-over-hours {
	color: red !important;
}

.employee-normal-hours {
	color: green !important;
}
