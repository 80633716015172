.search-filter {
	&__wrapper {
		padding: 2rem;
	}
	&__search {
		margin-right: 2rem;
	}
}

.pay-period-filter {
	width: 200px !important;
}
