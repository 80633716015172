.salary-expense-main {
    &__header {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    &__content {
        &--row {
            margin-bottom: 15px;

            &--title {
                font-size: 16px;
                font-weight: 400;
            }

            &--value {
                color: #1543CC;
                font-size: 16px;
                font-weight: 400;
                text-decoration: underline;

                :hover {
                    color: #1543CC;
                    text-decoration: underline;
                }
            }
        }
    }
}