@import '../../../scss/mixin.scss';

.login-body {
	&__wrapper {
	}
	&__top {
		&--title {
			font-weight: 700;
			font-size: 3.4rem;
			margin-bottom: 1rem;
			@include mq('desktop') {
				margin-bottom: 2.2rem;
			}
		}
		&--description {
			& > p {
				font-size: 17px;
				color: #425465;
				margin-bottom: 3rem;
				@include mq('desktop') {
					margin-bottom: 3.3rem;
				}
			}
		}
	}
	&__center {
	}
	&__forgot-password {
		display: flex;
		justify-content: left;
		font-size: 14px;
		margin-bottom: 2.5rem;
		font-weight: 500;
		color: #344735;
		border-bottom: 1px solid #344735;
		display: flex;
		justify-content: flex-end;
		width: max-content;
		margin-left: auto;
		&:hover {
			cursor: pointer;
		}
	}
	&__end {
		&--button {
			width: 100%;
			font-size: 2rem !important;
			background: #000;
			height: 5.4rem !important;
			&:hover {
				background: #000 !important;
			}
		}
	}
}

.intuit_button {
	background: #0b78c2;
	color: white;
	font-size: 2rem !important;
	height: 5rem !important;
	padding: 0rem 6rem;
	margin-bottom: 2.5rem;
	border: none;
	outline: none;
	border-radius: 0.8rem;

	&_container {
		display: flex !important;
		justify-content: center !important;
		margin-top: 2rem !important;
	}

	&:hover {
		cursor: pointer;
	}
}
