.search-filter-main-picker-right {
	.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
		background-color: #009049 !important;
		border-color: #d4d7dc !important;
		outline: none !important;
	}

	:where(.css-dev-only-do-not-override-1m62vyb).ant-checkbox-checked
		.ant-checkbox-inner {
		background-color: #009049 !important;
		border-color: #d4d7dc !important;
		outline: none !important;
	}

	.ant-table-cell .ant-checkbox-checked .ant-checkbox-inner {
		background-color: #009049;
	}
}

.config-link {
	cursor: pointer;
	margin-left: 10px;
	text-decoration: underline;
	font-size: 16px;
	color: blue;
}
