.employee-container {
	&__title {
		font-weight: 700;
		font-size: 2.4rem;
		margin-bottom: 2rem;
	}
}

.search-filter {
	padding: 2rem !important;
}

.table-employee thead th {
	background: #485949 !important;
	color: #fff !important;
	border-radius: 0px !important;
	padding: 1.4rem !important;
}

.table-employee thead th:hover {
	background: #485949 !important;
	color: #fff !important;
	cursor: pointer;
}

.table-employee {
	background-color: #fff !important;
	border-radius: 1rem !important;
}

.clock {
	display: flex !important;
}

.dynamic-table {
	&-total {
		// text-align: center !important;
		font-weight: 600;
	}
	&__employee {
		color: #2c9f1c !important;
		// text-decoration: none !important;

		&-name {
			margin-right: 1rem !important;
			&-inactive {
				color: red !important;
			}
		}
	}

	&__status {
		display: flex;
		align-items: center;
		gap: 0.7rem;
	}

	&__calculator {
		display: flex;
		align-items: center;
		&:hover {
			cursor: pointer !important;
		}
	}
}

.employee-costs-export {
	position: absolute;
	top: -74px;
	right: 10px;
	display: flex;
	gap: 3rem !important;
}

.search-filter-second {
	display: flex !important;
	align-items: center !important;
}

.time-sheet-modal__header {
	padding: 1.1rem 1rem !important;
	display: flex;
	gap: 1.1rem;
	align-items: center;
	width: 100%;
	min-height: 5.6rem !important;
	justify-content: space-between;

	&--left {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 2.7rem;
	}

	&--avatar {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 7rem;
		height: 7rem;

		img {
			width: 7rem;
			height: 7rem;
			border-radius: 3.4rem;
			object-fit: cover !important;
			object-position: top !important;
		}
	}

	&--upload {
		button {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&--text {
			color: #ffffff;
		}
	}

	&-delete {
		border: none !important;

		&:hover {
			cursor: pointer;
		}
	}
}

.CustomMultipleFormModal {
	width: 70% !important;
}
