.Success {
	color: green;
}

.Failed {
	color: red;
}

.search-filter {
	&__wrapper {
		padding: 2rem;
	}
	&__search {
		width: 200px !important;
	}
}
