.main-container {
	width: calc(100vw - 300px);
	&__title {
		font-weight: 600;
		font-size: 2rem;
		line-height: 2.5rem;
	}
}

.connection-table {
	&__logo {
		height: 3.6rem;
		width: 18.658rem;
	}

	&__action {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		font-weight: 600;

		& > a {
			text-decoration: none !important;
			color: black;
		}

		:hover {
			cursor: pointer;
		}
	}

	&__company {
		&-title {
			font-weight: 600;
			font-size: 1.6rem;
			line-height: 2rem;
			margin-bottom: 0.6rem;
		}
		&-year {
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.25rem;
			color: #666666;
		}
		&-currency {
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.25rem;
			color: #666666;
		}
	}

	&__connection-id {
		font-weight: 600 !important;
	}

	&__last-sync {
		font-weight: 600 !important;
	}
}
