.search-filter {
	&__wrapper {
		padding: 2rem;
	}
	&__search {
		width: 40rem !important;
	}
	&__filter {
		width: 18rem !important;
	}
}

.pay-period-filter {
	width: 200px !important;
}
